import React, { useMemo, useState, Fragment } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/SEO';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import BlockContent from '@sanity/block-content-to-react';
import slugify from '../../slugify';
import { center, left, right } from '../../marks/alignment';
import Select from 'react-select';
import { SocialShareLinks } from '../../components/SocialShareLinks';
import { useShowData } from '../../utils/useShowData';
import { useLocationData } from '../../utils/useLocationData';

const sortOptions = [
  {
    value: 'ASC',
    label: 'Sort by Date (Soonest)',
  },
  {
    value: 'DESC',
    label: 'Sort by Date (Furthest Away)',
  },
];

const AllShows = ({ location }) => {
  const { allSanityShow, allSanityLocation } = useStaticQuery(graphql`
    query {
      allSanityLocation {
        nodes {
          id
          address
          town
          county
        }
      }
      allSanityShow(sort: { fields: date, order: DESC }) {
        nodes {
          comedians {
            name
            image {
              ...ImageWithPreview
            }
          }
          date
          doors_open
          location {
            id
            address
            town
            county
          }
          postponed
          postponedText
          show_starts
          ticket_url
          ticketAvailabilityText {
            text
          }
          ticketAvailability
          _rawDescription
          compere {
            image {
              ...ImageWithPreview
            }
            name
          }
        }
      }
    }
  `);

  const [currentTownFilter, setTownFilter] = useState();
  const [currentCountyFilter, setCountyFilter] = useState();

  const [shows] = useShowData(allSanityShow);
  const [towns, counties, locations] = useLocationData(allSanityLocation);

  const upcomingShows = useMemo(() => {
    return shows;
  }, [shows]);

  const [currentVenueFilter, setVenueFilter] = useState(null);
  const [currentSortOrder, setSortOrder] = useState('ASC');

  const filteredAndSortedShows = useMemo(() => {
    return upcomingShows
      .filter(show => {
        let isShowVisible = true;
        if (currentVenueFilter && show.location.id !== currentVenueFilter) {
          isShowVisible = false;
          return isShowVisible;
        }
        if (currentTownFilter && show.location.town !== currentTownFilter) {
          isShowVisible = false;
          return isShowVisible;
        }
        if (currentCountyFilter && show.location.county !== currentCountyFilter) {
          isShowVisible = false;
          return isShowVisible;
        }
        return isShowVisible;
      })
      .sort((a, b) => {
        if (currentSortOrder === 'ASC') {
          return a.date - b.date;
        }
        return b.date - a.date;
      });
  }, [currentVenueFilter, currentTownFilter, currentCountyFilter, currentSortOrder, upcomingShows]);

  const countiesValue = useMemo(() => {
    return currentCountyFilter && counties.filter(option => option.value === currentCountyFilter);
  }, [currentCountyFilter, counties]);

  const townValue = useMemo(() => {
    return currentTownFilter && towns.filter(option => option.value === currentTownFilter);
  }, [currentTownFilter, towns]);

  const venueValue = useMemo(() => {
    return currentVenueFilter && locations.filter(option => option.value === currentVenueFilter);
  }, [currentVenueFilter, locations]);

  const sortValue = useMemo(() => {
    return sortOptions.filter(option => option.value === currentSortOrder);
  }, [currentSortOrder]);

  return (
    <Layout location={location}>
      <SEO title="Upcoming Shows" />
      <div className={'flex justify-between items-center  mb-6'}>
        <h1 className={'text-lg page-label'}>{'Upcoming Shows'}</h1>
      </div>
      <div className={'text-black flex flex-col md:flex-row mt-4 mb-6'}>
        <Select
          className={'w-full md:w-64  h-full mr-4 mb-2'}
          value={countiesValue}
          onChange={option => setCountyFilter(option ? option.value : option)}
          options={counties}
          isClearable={true}
          placeholder={'Filter by county'}
        />
        <Select
          className={'w-full md:w-64  h-full mr-4  mb-2'}
          value={townValue}
          onChange={option => setTownFilter(option ? option.value : option)}
          options={towns}
          isClearable={true}
          placeholder={'Filter by town'}
        />
        <Select
          className={'w-full md:w-64 h-full mr-4 mb-2'}
          value={venueValue}
          onChange={option => setVenueFilter(option ? option.value : option)}
          options={locations}
          isClearable={true}
          placeholder={'Filter by venue'}
        />
        <Select
          className={'w-full md:w-64  h-full mb-2'}
          value={sortValue}
          onChange={({ value }) => setSortOrder(value)}
          options={sortOptions}
        />
      </div>
      <div className={'upcoming-shows flex flex-wrap'}>
        {upcomingShows.length === 0 && (
          <p className={'text-center flex justify-center my-8 flex-1'}>
            {'There are currently no upcoming shows.'}
            <br />
            {'Please check back later.'}
          </p>
        )}
        {filteredAndSortedShows.length === 0 && upcomingShows > 0 && (
          <p className={'text-center flex justify-center my-8 flex-1'}>
            {"We couldn't find any shows with those filters."}
            <br />
            {'Please clear your filters and try again'}
          </p>
        )}
        {filteredAndSortedShows.map(show => (
          <Fragment key={show.id}>
            <hr className={'w-full border-gray-800'} />
            <div className={'w-full mt-24 rounded mb-24'} id={show.id}>
              <div className={'show-header flex flex-col-reverse md:flex-row justify-between'}>
                <div className={'details  max-w-2xl'}>
                  <p className={'font-extrabold text-2xl'}>
                    {show.location ? show.location.address : 'Unknown Location'}
                  </p>
                  {show.doors_open && <div className={'py-2 font-semibold'}>Doors Open: {show.doors_open}</div>}
                  {show.show_starts && (
                    <div className={'py-2 font-semibold rounded-b'}>Show Starts: {show.show_starts}</div>
                  )}
                  <hr className={'border-gray-800'} />
                  <div className={'show-description my-6'}>
                    <BlockContent blocks={show._rawDescription} serializers={{ marks: { left, center, right } }} />
                    {show.postponed && (
                      <p>
                        <strong>
                          {show.postponedText
                            ? show.postponedText
                            : 'Apologies, this show is postponed until further notice.'}
                        </strong>
                      </p>
                    )}
                  </div>
                </div>
                <div className={' mb-4'}>
                  <div className={'rounded justify-end text-center mb-3 w-full md:w-56'}>
                    <div className={'flex justify-between font-extrabold'}>
                      <span>{show.month}</span>
                      <span>{show.year}</span>
                    </div>
                    <hr className={'border-gray-800'} />
                    <p className={'text-5xl font-extrabold mb-0'}>
                      {show.dayNumeric}
                      {show.ordinalDate}
                    </p>
                    <p className={'text-3xl font-extrabold mt-0 leading-none'}>{show.dayText}</p>
                  </div>
                  <div className={'flex justify-between'}>
                    <SocialShareLinks
                      url={`https://comedybeats.com/upcoming-shows#${show.showAnchor}`}
                      text={`Check out this show at ${show.location.address} on ${show.formattedDate}`}
                    />
                  </div>
                  <div className={'rounded w-full'}>
                    <a
                      href={`http://maps.google.com/?q=${show.location.address}`}
                      rel="noopener noreferrer"
                      target={'_blank'}
                      className={'w-full block bg-white px-3 py-2 text-black text-center rounded mt-4'}>
                      Google Maps Link
                    </a>
                  </div>
                </div>
              </div>
              <div className={'flex justify-between overflow-x-auto'}>
                {show.compere && (
                  <div className={'compere'}>
                    <p className={'label text-sm mb-2'}>{'Compere'}</p>
                    <Link className={'comedian'} to={`/comedians/${show.compereSlug}`}>
                      <Image
                        className={'max-w-full mr-4 w-40 p-4 shadow-md rounded-lg '}
                        {...show.compere.image}
                        width={132}
                        alt={`Image of compere: ${show.compere.name}`}
                      />
                      <span className={'act-label mt-4 text-xs block'}>{show.compere.name}</span>
                    </Link>
                  </div>
                )}
                {show.comedians.length > 0 && (
                  <div className={'comedians'}>
                    <p className={'label text-sm mb-2 font-medium'}>{'Acts'}</p>
                    <div className={'flex'}>
                      {show.comedians.map((comedian, index) => (
                        <Link className={'comedian'} to={`/comedians/${slugify(comedian.name)}`} key={index}>
                          <Image
                            className={`max-w-full w-40 p-4 rounded-lg ${
                              index !== show.comedians.length - 1 ? 'mr-8' : ''
                            }`}
                            {...comedian.image}
                            width={132}
                            alt={`Image of ${comedian.name}`}
                          />
                          <span className={'act-label mt-4 text-xs block'}>{comedian.name}</span>
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {show.postponed ? (
                <p className={'font-bold mt-8 text-center'}>{'Show is postponed'}</p>
              ) : (
                <div>
                  {show.ticket_url && !show.ticketAvailability && (
                    <a
                      href={show.ticket_url}
                      className={
                        'w-full border block text-center p-6 mt-8 text-sm hover:bg-white hover:text-black transition duration-150 ease-in-out'
                      }>
                      {'Tickets available here!'}
                    </a>
                  )}
                  {show.ticketAvailability && show.ticketAvailability?.text && (
                    <p className={'font-bold mt-8 text-center'}>{show.ticketAvailabilityText.text}</p>
                  )}
                  {!show.ticket_url && !show.ticketAvailability && (
                    <p className={'font-bold mt-8 text-center'}>{'Tickets no longer available.'}</p>
                  )}
                </div>
              )}
            </div>
          </Fragment>
        ))}
      </div>
    </Layout>
  );
};

export default AllShows;
